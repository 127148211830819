@import './variables.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(16, 1fr);
  margin-top: 10px;
}

.grid-item {
  transition: all 0.2s;
  cursor: pointer;
  height: 5.5vmin;
  width: 5.5vmin;
  border: 1px solid black;
  background-color: $darkest-grey;
  box-sizing: border-box;
  border-radius: 0.3vmin;
}

.grid-item:hover {
  background-color: $grey;
}

// important #2
// Lives in Grid.tsx
// .grid div.grid-item.enabled {
//   background-color: yellow;
// }

/* rules for active column, aka the playback marker */
@for $column from 0 through 15 {
  .column-#{$column}-active .column-#{$column} { background-color: $dark-grey; }
}

// Animations
@for $row from 0 through 15 {
  @for $column from 0 through 15 {
    // Item itself, import #1
    .grid.active-item-#{$row}-#{$column} .item-#{$row}-#{$column} {
      background-color: white !important;
    }

    // Neighbor, important #3
    .grid.active-neighbor-#{$row}-#{$column} .neighbor-#{$row}-#{$column} {
      background-color: $light-grey;
    }


    // Second neighbor, important #4
    .active-second-neighbor-#{$row}-#{$column} .second-neighbor-#{$row}-#{$column} {
      background-color: $dark-grey;
    }

  }
}
