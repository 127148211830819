@import './variables.scss';

#play-button {
  cursor: pointer;
  border: 1px solid black;
  background-color: $darkest-grey;
  height: 6vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex-grow: 7; // out of 8
}

#play-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6vmin solid #BBB;
  border-top: 3vmin solid transparent;
  border-bottom: 3vmin solid transparent;
  transition: opacity 0.3s;
}

#stop-square {
  position: absolute;
  width: 6vmin;
  height: 6vmin;
  background-color: #BBB;
  transition: opacity 0.3s;
}


