@import './variables.scss';

#save-modal {
  display: flex;
  flex-direction: column;
  min-height: 21rem; // This is so the save secret blurb is always inside the box. text :sigh

  & #left-items {
    align-self: flex-start;
    position: absolute;
    margin: 0;
    color: $light-grey;
    display: flex;
    flex-direction: column;
    transition: opacity 0.6s ease-in;
  }

  & #connections-readout {
    padding: 0rem 0.3rem;
    width: 0.6rem;
    border-radius: 100%;
    border: 1px solid $light-grey;
    margin-top: 0.6vmin;
  }

  & #sign-out {
    cursor: pointer;
  }

  & .close-button {
    font-size: 1.5rem;
    align-self: flex-end;
    position: absolute;
    padding: 1.5vmin 3vmin;
    background-color: $dark-grey;
    border-radius: 0.5vmin;
    color: $light-grey;
  }

  & hr {
    width: 100%;
  }

}

#save-modal-body {
  & .row {
    display: flex;
    flex-direction: row;
  }

  h4 {
    margin: 0;
    margin-bottom: 1vmin;
  }

  h6 {
    margin: 0;
  }

  // General for an input and a submit button
  & .input-group {
    display: flex;

    input {
      padding: 3vmin;
      text-align: center;
      border-top-left-radius: 2vmin;
      border-bottom-left-radius: 2vmin;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      flex-grow: 5;
      border: none;
      width: 100%;

      &:focus-visible {
        outline: none;
      }
    }

    button {
      padding: 3vmin;
      flex-grow: 1;
      border-top-right-radius: 2vmin;
      border-bottom-right-radius: 2vmin;
      border: none;
      width: 7em;
      cursor: pointer;
      background: $light-grey;
      color: white;
      min-width: max-content;
    }
  }

  .input-name {
    transition: max-height 0.3s ease-out;
    max-height: 0;
    overflow: hidden;

    &.visible {
      max-height: 10em;
      transition: max-height 0.3s ease-in;
    }
  }

  .db-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2vmin;

    h5 {
      margin: 0;
      margin-right: 2vmin;
    }

    & .name-group {
      // text-align: center;
    }

    & .user-name {
      min-width: 7em;
      width: 7em; // both this and above are necessary for word-break and size
      word-break: break-all;
    }

    & .update-name-button {
      padding: 1em;
      padding-left: 0;
      background: $dark-grey;
      border-radius: 0.3em;
      color: darkgreen;
      width: max-content;
      user-select: none;
      border: none;
    }

    & .block-button {
      padding: 1em;
      padding-left: 0;
      background: $dark-grey;
      border-radius: 0.3em;
      color: darkred;
      width: max-content;
      user-select: none;
      border: none;
    }

    & .delete-button {
      background: $dark-grey;
      border: none;
      color: $light-grey;
      border-radius: 0.1em;
    }
  }

  .mini-grid {
    font-size: 0.6rem;
    // line-height: 0.375rem;
    line-height: 0.625em; // Both this and above work but I think it's more correct to do em
    user-select: none;
    margin: 0;
    margin-right: 1vmin;
  }

  #load-section {
    overflow: auto;
    max-height: calc(75vh - 15rem); // A desperate attempt to keep this section within the modal bounds so it can scroll independently

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}

