@import './variables.scss';

#settings-modal {
  display: flex;
  flex-direction: column;
  overflow-x: clip;
  overflow-y: auto;
  position: fixed;
  top: 5vh;
  bottom: 5vh;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  & .close-button {
    align-self: flex-end;
    position: absolute;
    padding: 1vmin 2.5vmin;
    background-color: $dark-grey;
    border-radius: 0.5vmin;
    color: $light-grey;
  }

  & hr {
    width: 100%;
  }

  & .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3vmin;
    margin-bottom: 3vmin;

    & h3 { // these are meant to be the headers
      margin: 0;
      margin-right: 2vmin;
      min-width: 5rem;
    }
  }

  & .tempo-indicator {
    border-radius: 0.5vmin;
    border: 1px solid white;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1em;
    background-color: $dark-grey;
    color: white;
    width: 3rem;
    margin-right: 3vmin;
    height: auto;
    text-align: center;
  }

  & .tap-tempo-button {
    background-color: $grey;
    padding: 3vmin;
    border-radius: 1vmin;
    border: 1px solid white;
    cursor: pointer;
    user-select: none;
    font-size: 3vmin;

    &:active {
      background-color: $light-grey;
    }
  }

  & .tempo-update-check {
    color: green;
    font-size: 4vmin;
    border: 1px solid green;
    border-radius: 100%;
    width: 4vmin;
    height: 4vmin;
    margin-left: 3vmin;
    transition: opacity 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  & .tuning-selection {
    border-radius: 0.5vmin;
    padding: 1em;
    margin-right: 2vmin;
    margin-bottom: 2vmin;
    height: 7vmin;
    width: 15vmin;
    background-color: $grey;
    font-size: 3vmin;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    &.active {
      border: 1px solid white;
    }
  }

  & #clear-grid-button {
    background-color: $grey;
    color: white;
    border: none;
    padding: 3vmin;
    border-radius: 0.5vmin;
    border: 2px solid red;
    font-size: 3vmin;

    &:hover {
      border: 2px solid red;
    }
  }

  // button to unblock a mofo
  & .blocked-user {
    background-color: $grey;
    border: 2px solid green;
    color: white;
    padding: 3vmin;
    border-radius: 0.5vmin;
    font-size: 3vmin;
    margin: 1vmin;
  }
}
