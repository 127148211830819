@import './variables.scss';

#save-button {
  background-color: $darkest-grey;
  cursor: pointer;
  flex-grow: 1; // out of 8
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  color: white;
  margin: 1px;

  & img {
    width: 5vmin;
    filter: contrast(0);
  }
}


