@import './variables.scss';

body {
  background-color: black;
  color: white;
  overflow-y: clip;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hidden {
  opacity: 0;
}

#button-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1vh;
}

#github {
  position: absolute;
  right: 2vh;
  top: 2vh;
  z-index: -1; // under modals
}

#github img {
  filter: brightness(15%);
}

// TODO remove
#img.kofiimg {
  filter: brightness(15%);
}

.button-effects {
  cursor: pointer;
  transition: filter 0.3s;

  &:hover {
    filter: brightness(200%);
  }

  &:active {
    filter: brightness(250%);
  }

  &:disabled {
    filter: brightness(50%) grayscale(1) !important;
    cursor: default !important;
  }
}

.button-sizing {
  font-size: 5vmin;
}

.modal {
  position: fixed;
  top: 5vh;
  width: 80vmin;
  background-color: $dark-grey;
  border: 2px double white;
  border-radius: 2vmin;
  padding: 3vmin;
  transition: transform 0.2s;
  transform: rotateY(90deg);
  z-index: 100;
  margin-left: 0.55vmin; // I'm not quite sure why but this is what it takes to center this

  &.open {
    transform: rotateY(0deg);
  }
}
