@import './variables.scss';

#secret-prompt {
  align-self: center;
  margin-top: 5vmin;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: opacity 0.3s; // this'll happen once after secret is input

  & form {
    display: flex;

    & label {
      display: none;
    }
  }

  & .secret-prompt-row {
    display: flex;
    flex-direction: row;
  }

  & input {
    padding: 3vmin;
    text-align: center;
    border-top-left-radius: 2vmin;
    border-bottom-left-radius: 2vmin;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 80%;
    border: none;

    &:focus-visible {
      outline: none;
    }
  }

  & button#secret-prompt-submit {
    padding: 3vmin;
    border-top-right-radius: 2vmin;
    border-bottom-right-radius: 2vmin;
    border: none;
    height: 100%;
    width: 10vmin;
    cursor: pointer;
    background: $light-grey;
    color: white;
  }

  & button#generate-new-secret {
    background: $light-grey;
    border: none;
    padding: 3vmin;
    border-radius: 2vmin;
    color: yellow;
  }

  & p#explanation {
    transition: opacity 0.3s;

    &.error {
      color: red;
    }
  }

  // The Browser Network link
  & a {
    color: white;
  }
}
